import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Thursday 14th September 2021 we will be making the following changes to our mortgage product
      range:</p>
    <p><strong>Summary of changes</strong></p>

    <p><strong>Residential Existing Customer Switching Range:</strong></p>
    <p>2 Year Fixed Standard at 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</p>
    <p>2 Year Tracker Standard at 60%, 70% and 75% LTV have reduced</p>
    <p>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced </p>
    <p>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced </p>
    <p>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Fee Saver at 60% 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</p>
    <p>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>

    <p><strong>Residential Existing Customer Borrowing More Range:</strong></p>
    <p>2 Year Fixed Standard at 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>2 Year Tracker Standard at 60%, 70% and 75% LTV have reduced</p>
    <p>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced </p>
    <p>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced </p>
    <p>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Fee Saver at 60% 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>

    <p><strong>Residential Remortgage Range:</strong></p>
    <p>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</p>
    <p>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</p>
    <p>2 Year Tracker Standard at 60%, 70% and 75% LTV have reduced</p>
    <p>3 Year Fixed Standard at 80% and 85% LTV have reduced </p>
    <p>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85%, LTV have reduced </p>
    <p>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>

    <p><strong>Residential First Time Buyer Range:</strong></p>
    <p>2 Year Fixed Standard at 70% 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% (MGS) LTV have reduced</p>
    <p>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% (MGS) LTV have reduced</p>
    <p>5 Year Fixed Premier Exclusive at 60%, 70%, 75, 80%, 85% and 90% LTV reduced</p>

    <p><strong>Residential Home Mover Range:</strong></p>
    <p>2 Year Fixed Standard at 70% 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% (MGS) LTV have reduced</p>
    <p>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</p>
    <p>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% (MGS) LTV have reduced</p>
    <p>5 Year Fixed Premier Exclusive at 60%, 70%, 75, 80%, 85% and 90% LTV reduced</p>

    <p>There are no other changes to our fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 14th September 2021 to reflect these
      changes.</p>

    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
